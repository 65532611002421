import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import 'firebase/database'

export default class ContactPage extends React.Component {
  state = {
    name: "",
    email: "",
    message: "",
    submitting: false,
  }

  handleInputChange = event => {
    const target = event.target,
          value = target.value,
          name = target.name;

    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    event.preventDefault();

    if(!event.target.checkValidity()) {
      //Do nothing
    } else {

      this.setState({
        submitting: true,
      })
    
      const data = this.state;

      fetch('https://us-central1-personal-site-3a7ac.cloudfunctions.net/app/api/messages', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.errors) {
            console.log(res.errors);
          }
          if (res.isEmailSend) {
            this.setState({
              name: "",
              email: "",
              message: "",
              submitting: false
            });
          }
          return res;
        })
        .catch((errors) => {
          console.log(errors);
          this.setState({
            submitting: false
          });
      });
    }
  };

  render() {
    return (
      <Layout>
        <SEO title="Contact" />
        <div className="container row col-12 m-0 py-4">
          <h2 className="col-12 text-center pb-3">Contact me</h2>
          <div className="col-md-7 mx-auto" >
            <form className="needs-validation" onSubmit={this.handleSubmit}>
              <div className="form-group col-12 px-0">
                <label style={{width: "100%"}} htmlFor="name">Name*
                  <input className="form-control" id="name" type="text" name="name" value={this.state.name} onChange={this.handleInputChange} placeholder="Enter your name" required disabled={this.state.submitting} />
                </label>
              </div>

              <div className="form-group col-12 px-0">
                <label style={{width: "100%"}} htmlFor="email">Email address*
                  <input className="form-control" id="email" type="email" name="email" value={this.state.email} onChange={this.handleInputChange} placeholder="Enter your email address" required disabled={this.state.submitting} />
                </label>
              </div>

              <div className="form-group col-12 px-0">
                <label style={{width: "100%"}} htmlFor="message">Message*
                  <textarea className="form-control" id="message" name="message" value={this.state.message} onChange={this.handleInputChange} placeholder="Enter your message" rows="4" required disabled={this.state.submitting} />
                </label>
              </div>
              <button className="btn btn-primary" type="submit" disabled={this.state.submitting}>
                Submit
              </button>
            </form>
          </div>
        </div>
      </Layout>
    )
  }
}